<template>
  <picture>
    <source
      v-for="source in sources"
      :media="source.media"
      :srcset="source.srcset"
      type="image/webp"
    />
    <img :src="defaultImage" :alt="alt" :class="imageClass" :srcset="srcset" :sizes="sizes" :loading="loadingMode" type="image/webp" />
  </picture>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';

const props = defineProps({
  imageUrl: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    default: '',
  },
  imageClass: {
    type: String,
    default: '',
  },
  lazyLoading: {
    type: Boolean,
    default: false,
  },
});

// refs
const sizes = '100vw';
const responsiveImageSizes = [1920, 1600, 1440, 1366, 1024, 828, 768, 640];
const defaultWidth = 1920;
const defaultExtension = '.webp';

// methods
const getImageUrlWithWidth = (width: number, ext: string) => {
  return `${props.imageUrl}-${width + ext}`;
};

// computed
const loadingMode = computed(() => (props.lazyLoading ? 'lazy' : null));

const defaultImage = computed(() => {
  return getImageUrlWithWidth(defaultWidth, defaultExtension)
});

const srcset = computed(() => {
  return responsiveImageSizes
    .map((finalWidth: number) => {
      return getImageUrlWithWidth(finalWidth, defaultExtension) + ` ${finalWidth}w`;
    })
    .join(', ');
});

const sources = computed(() => {
  return responsiveImageSizes
    .map((finalWidth: number) => {
      return {
        media: `(min-width: ${finalWidth}px)`,
        srcset: getImageUrlWithWidth(finalWidth, '.webp'),
      };
    });
});

</script>
